
import React from 'react';
import styled from 'styled-components';
import Contact from '../components/Icons/contact'; 
import Cart from "../components/Icons/cart"
import store from '../../../stores/common-store';
export const SvgIconDiv = styled.div`
  cursor: pointer;
  svg {
    stroke-width: 3px;
    stroke: black;
    width: 28px;
    height: 28px;
    fill: white;
    /* margin: 0px 20px; */
    @media (max-width: 767px) {
      margin: 0px;
    }
  }
`; 
 
export const  navLinks =   [
  {
    id: 1,
    text: "Pagespeed",
    url: "/pages/pagespeed",
    is_main: false,
    isAvailbelOnTablet: true,
    inMore: false
  },
  {
    id: 2,
    text: "Data & Analytics",
    url: "/pages/data-analytics/",
    is_main: false,
    isAvailbelOnTablet: true,
    inMore: false
  },
  {
    id: 3,
    text: "Custom Cart",
    url: "/pages/shopify-cart/",
    is_main: false,
    isAvailbelOnTablet: true,
    inMore: false
  },
  {
      id: 4,
      text: "SEO",
      url: "/pages/seo/",
      is_main: false,
      isAvailbelOnTablet: false,
      inMore: false
    }, 
    {
      id: 5,
      text: "Email Marketing",
      url: "/pages/email-marketing/",
      is_main: false,
      isAvailbelOnTablet: false,
      inMore: false
    },
    {
      id: 6,
      text: "Customizations",
      url: "/pages/customizations/",
      is_main: false,
      isAvailbelOnTablet: false,
      inMore: true
    },
    
    {
      id: 7,
      text: "Affiliate Marketing",
      url: "/pages/affiliate-marketing/",
      is_main: false,
      isAvailbelOnTablet: false,
      inMore: true
    },
    { 
      id: 8,
      text: "A/b Testing",
      url: "/pages/ab-testing/",
      is_main: false,
      isAvailbelOnTablet: false,
      inMore: true
    },
    { 
      id: 9,
      text: "Digital Design",
      url: "/pages/digital-design/",
      is_main: false,
      isAvailbelOnTablet: false,
      inMore: true
    },
    { 
      id: 10,
      text: "About Us",
      url: "/pages/contact-us/",
      is_main: false,
      isAvailbelOnTablet: true,
      inMore: false
    },
    // { 
    //   id: 11,
    //   text: "Contact Us",
    //   url: "/pages/contact-us/",
    //   is_main: false,
    //   isAvailbelOnTablet: true,
    //   inMore: false
    // },
  ]
 
 
export const  SideIcons =   [
  {
    id: 1, 
    text: <SvgIconDiv><Contact width={20} /></SvgIconDiv>,
    url: "/account", 
    is_main: false,
  },
  {
    id: 1, 
    text: <SvgIconDiv><Cart width={20} /></SvgIconDiv>,
    onClick:(e)=>{ 
      e.preventDefault();
      store.showCart();
    },
    is_main: true,
  },
]

